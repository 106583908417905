<template>
  <div class="page-wrapper white">
    <div class="page-info search-wrapper w-200">
      <div class="search-item">
        <el-select v-model="formData.school_id" placeholder="请选择校区" size="small">
          <el-option v-for="item of school" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-item w-280">
        <el-input v-model="formData.student_nos" placeholder="请输入学号多学生使用“，”号分割" size="small" @input="(v) => (formData.student_nos = v.replace(/[^\d,，]/g, ''))"></el-input>
      </div>
      <div class="search-item">
        <el-button :disabled="disabled" size="small" type="primary" @click="onDownload">
          <span>提交</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getApi2 } from '@/api'

export default {
	_config:{"route":{"path":"index","meta":{"title":"列表"}}},
  data() {
    return {
      formData: {
        school_id: '',
        student_nos: '',
      },
      school: []
    }
  },
  computed: {
    disabled() {
      let isDisabled = false
      Object.keys(this.formData).forEach(key => {
        if (this.formData[key] || this.formData[key] === 0) {
          return
        }
        isDisabled = true
      })
      return isDisabled
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    getOptions() {
      getApi2('/common/screen/school').then(res => {
        this.school = res.map(item => ({
          value: item.id,
          label: item.school_name
        }))
      })
    },
    onDownload() {
      this.$_axios2.post('api/tool/student/transfer', this.formData, {logic: 1}).then(() => {
        this.$message.success('提交成功')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-date-editor,
  .el-select,
  .el-input {
    width: 100%;
  }
}
</style>
